import React from "react";

export const Services = (props) => {
  const servicesData = props.data || [];

  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
          <p>
            "Revolutionizing Today with Tomorrow's Intelligence: Unleashing the
            Power of AI-Driven Products for a Future-Ready World."
          </p>
        </div>
        <div className="row">
          {servicesData.map((d, i) => (
            <div
              key={`${d.name}-${i}`}
              className={`col-md-6 ${i % 2 === 0 ? "mb-4" : ""}`}
            >
              <i className={d.icon}></i>
              <div className="service-desc">
                <h3>{d.name}</h3>
                <p>{d.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

import React from "react";

const renderTeamStyle = {
  display: "flex",
  justifyContent: "center",
  gap: "50px",
  flexWrap: "wrap",
};
function renderTeam(team) {
  return (
    <>
      {team.map((member, index) => (
        <div className="thumbnail" key={`member-${index}`}>
          <img src={member.img} alt={`${member.name}`} className="team-img" />
          <div className="caption">
            <h4>{member.name}</h4>
            <p>{member.job}</p>
          </div>
        </div>
      ))}
    </>
  );
}
export const Team = ({ data: teamData }) => {
  if (!teamData) return <div>Loading...</div>;

  const coFounders = teamData.filter((member) => member.job === "Co-Founder");
  const sweEngineers = teamData.filter(
    (member) =>
      member.job === "Software Engineer" || member.job === "SWE - Intern"
  );

  return (
    <div id="team" className="text-center">
      <div className="section-title">
        <h2>Meet the Team</h2>
      </div>
      <div>
        <div style={renderTeamStyle}>{renderTeam(coFounders)}</div>
        <div style={renderTeamStyle}>{renderTeam(sweEngineers)}</div>
      </div>
    </div>
  );
};

import React from "react";


export const OurClients = (props) => {
  const projectsData = props.data || [];

  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Clients</h2>
          <p>
            "Client-Centric Solutions, Crafted with Precision: Your Success is
            Our Code."
          </p>
        </div>
        <div style={{ display: "flex", flexWrap: "wrap",justifyContent: "center" }}>
          {projectsData.map((d, i) => (
            <div key={`${d.title}-${i}`} className={`col-md-6 mb-4`}>
              <a href={d.website} target="_blank" rel="noreferrer">
                <img src={d.image} alt={d.title} className="portfolioImage" />
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

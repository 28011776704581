import React, { useState, useEffect } from "react";

export const Header = (props) => {
  const [typedTitle, setTypedTitle] = useState("");
  const title = props.data ? props.data.title : "Loading";
  useEffect(() => {
    let index = 0;
    const intervalId = setInterval(() => {
      setTypedTitle((prev) => prev + title[index]);
      index += 1;
      if (index === title.length) {
        clearInterval(intervalId);
      }
    }, 100);
    return () => clearInterval(intervalId);
  }, [title]);

  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1>
                  {typedTitle}
                </h1>
                <a
                  href="#services"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Learn More
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
